import LeftPanel from "./leftPanel/leftPanel";
import RightPanel from "./rightPanel.js/rightPanel";
import Header from "./header-landing/header";
import styles from "../mainStyle.module.css";
import { useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";

function LandingPage(props) {
  const [date, setDate] = useState();
  const [formattedDate, setFormattedDate] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [timestamp, setTimestamp] = useState();
  const [accTkn, setAccTkn] = useState();
  const [macAdd, setMacAdd] = useState();
  const [mode, setMode] = useState("");
  const [deviceData, setDeviceData] = useState({});
  const [l9data, setL9data] = useState([]);
  const [l12data, setL12data] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [energyconsumedData, setEnergeyConsumedData] = useState([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      window.location.reload();
    }, 900000); // 900000ms = 15 minutes

    return () => {
      clearInterval(intervalId);
    }; // Clear the interval when the component unmounts
  }, []);

  //Formatting date

  useEffect(() => {
    let d = new Date();
    if (date) {
      const selectedDate = `${date.getFullYear()}-${
        (date.getMonth() + 1).toString().length === 2
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`
      }-${
        date.getDate().toString().length === 2
          ? date.getDate()
          : `0${date.getDate()}`
      }`;

      setFormattedDate(selectedDate);
      setDate(date);

      const currDate = `${d.getFullYear()}-${
        (d.getMonth() + 1).toString().length === 2
          ? d.getMonth() + 1
          : `0${d.getMonth() + 1}`
      }-${
        d.getDate().toString().length === 2 ? d.getDate() : `0${d.getDate()}`
      }`;

      setFormattedDate(selectedDate);
      setCurrentDate(currDate);
      setDate(date);
      setTimestamp(date.getTime());
    }
  }, [date]);

  //Fetching Device List

  useEffect(() => {
    async function fetchDeviceList() {
      axios
        .get("https://energeek.starlly.in/api/deviceList/", {
          // headers: {
          //   "Access-Control-Allow-Origin": "*",
          //   "Content-Type": "application/json",
          // },
        })
        .then((response) => {
          if (response.status === 200) {
            // setAccTkn(response?.data[0]?.["access_token"]);
            setMacAdd(response?.data[0]?.macAddress);
          }
        })
        .catch((err) => {
          alert("Trouble fetching data");
        });
    }
    if (formattedDate && !macAdd) {
      fetchDeviceList();
    }
  }, [formattedDate]);

  //Fetching Device Data

  useEffect(() => {
    async function fetchDeviceData() {
      let body;

      if (formattedDate === currentDate) {
        body = {
          macAddress: macAdd,
          mode: "minute",
        };
      } else {
        body = {
          macAddress: macAdd,
          mode: "day",
          prevDateTime: formattedDate,
        };
      }

      axios
        .post("https://energeek.starlly.in/api/deviceData/", body)
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            // console.log(response);

            if (body.mode === "day") {
              setMode("day");
            } else {
              setMode("minute");
            }
            setDeviceData(response.data);
            // if (response?.data.length === 1) {
            //   // console.log(1);
            //   setDeviceData(response?.data[0]);
            // } else {
            //   // console.log(2);
            //   setDeviceData(response?.data[response?.data.length - 1]);
            // }
          }
        })
        .catch((err) => {
          alert("Trouble fetching data");
        });
    }
    if (macAdd) {
      fetchDeviceData();
    }
  }, [formattedDate, macAdd]);

  //fetching energy chart data

  useEffect(() => {
    async function fetchEnergyChartData() {
      try {
        const response = await axios.get(
          `https://energeek.starlly.in/api/energyData/?day=${formattedDate}`
        );
        if (response.status === 200) {
          setEnergeyConsumedData(response.data);
        }
      } catch (err) {
        alert("Trouble fetching energy chart data");
      }
    }

    if (timestamp) {
      let t;
      if (formattedDate === currentDate) {
        t = Date.now();
        // console.log(t);
      } else {
        t = timestamp;
      }
      fetchEnergyChartData();
    }
  }, [formattedDate, timestamp, currentDate]);

  useEffect(() => {
    let dataForChart = [];
    if (energyconsumedData) {
      const mergedData = {};

      energyconsumedData.forEach((energyData) => {
        energyData.energy.forEach((entry) => {
          const time = entry.time;
          const dynamicPropertyName = parseInt(energyData.floor); // Parse floor to integer
          const floorValue = entry.value; // Assuming value is always present
          if (!mergedData[time]) {
            mergedData[time] = {};
          }
          mergedData[time][dynamicPropertyName] =
            floorValue !== undefined ? floorValue : 0;
        });
      });

      const mergedArray = Object.keys(mergedData).map((time) => ({
        ...mergedData[time],
        time: time,
      }));

      dataForChart = mergedArray;
    }

    dataForChart = dataForChart.sort((a, b) => {
      const [hoursA, minutesA] = a.time.split(":").map(Number);
      const [hoursB, minutesB] = b.time.split(":").map(Number);

      if (hoursA !== hoursB) {
        return hoursA - hoursB;
      }
      return minutesA - minutesB;
    });
    setChartData(dataForChart);
  }, [energyconsumedData]);

  return (
    <>
      <img
        className={styles.globe}
        src="https://starllyprojects.s3.ap-south-1.amazonaws.com/energeek/earth.gif"
        alt="Globe"
      />
      <div className={styles.logo}></div>
      <div className={styles.mainContainer2}>
        <Header date={date} setIsAuthenticated={props.setIsAuthenticated} />
        <div className={styles.mainContainer2Sub}>
          <LeftPanel
            mode={mode}
            deviceData={deviceData}
            chartData={chartData}
          />
          <RightPanel setDate={setDate} deviceData={deviceData} />
        </div>
      </div>
    </>
  );
}
export default LandingPage;
